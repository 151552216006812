<template>
  <b-card-code title="Créer un nouveau livreur">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de livreur </span>
    </b-card-text>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  v-model="delivery_man.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="required|integer|length:8"
              >
                <b-form-input
                  v-model="delivery_man.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="delivery_man.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="adress"
            >
              <validation-provider
                #default="{ errors }"
                name="adress"
              >
                <b-form-input
                  v-model="delivery_man.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Mot de passe"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- confirm password -->
          <b-col cols="6">
            <b-form-group
              label="Confirmer mot de passe"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ac-password"
                  v-model="delivery_man.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Voiture"
            >
              <validation-provider
                #default="{ errors }"
                name="voiture"
                rules="required"
              >
                <v-select
                  v-model="delivery_man.car"
                  :clearable="false"
                  placeholder="voitures"
                  label="serial_number"
                  :options="cars"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Dépot"
              label-for="depot"
            >
              <validation-provider
                #default="{ errors }"
                name="depot"
                rules="required"
              >
                <v-select
                  v-model="delivery_man.repository"
                  :clearable="false"
                  placeholder="Dépots"
                  label="name"
                  :options="repositories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addDeliveryMan"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      password: '',
      repository: '',
      repositories: [],
      cars: [],
      delivery_man: {
        car: '',
        username: '',
        email: '',
        password: '',
        address: '',
        phone: '',
      },
      required,
      isLoading: false,
    }
  },
  created() {
    this.getCars()
    this.getRepositories()
  },
  methods: {
    async getCars() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/cars/')
        this.cars = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async getRepositories() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/repositories/')
        this.repositories = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async addDeliveryMan() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios.post('/api/auth/register/driver/',
              {
                email: this.delivery_man.email,
                password: this.delivery_man.password,
                username: this.delivery_man.username,
                phone: this.delivery_man.phone,
                car: this.delivery_man.car.id,
                address: this.delivery_man.address,
                repository: this.delivery_man.repository.id,
              })
            this.$router.push('/delivery-mans')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'livreur ajouté avec succés')
            }, 1000)
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
